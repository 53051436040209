import React from "react";
import styles from "./benefits.module.scss";
import { ReactComponent as CityIcon } from "./images/house.svg";
import { ReactComponent as CoinsIcon } from "./images/coins.svg";
import { ReactComponent as DiplomaIcon } from "./images/diploma.svg";
import { ReactComponent as DocsIcon } from "./images/docs.svg";

function Benefits({ page }) {
  let headingText, itemText;

  switch (page) {
    case "Emirates":
      headingText = "в Дубае";
      itemText = "в Земельном департаменте Дубая";
      break;
    case "Turkey":
      headingText = "в Турции";
      itemText = "";
      break;
    default:
      headingText = "зарубежом";
      itemText = "";
      break;
  }

  const content = [
    {
      icon: <CityIcon />,
      name: "Выбрать интересующий тип дома",
      text: "Поможем выбрать объект от большого числа подрядчиков",
    },
    {
      icon: <DocsIcon />,
      name: "Выбрать на платформе подрядчика ",
      text: "Поможем оформить все необходимые документы",
    },
    {
      icon: <DiplomaIcon />,
      name: "Подписать договор подряда",
      text: `Поможем зарегистрировать сделку ${itemText}`,
    },
    {
      icon: <CoinsIcon />,
      name: "Оплатить покупку",
      text: "Поможем разобраться во всех бюрократических тонкостях  ",
    },
  ];

  return (
    <div className={styles.benefits}>
      <div className={styles.heading}>
        Построить дом на своем участке или купить готовый дом c участком — это просто
      </div>
      <div className={styles.items}>
        {content.map((item, index) => (
          <div className={styles.item} key={index}>
            <div className={styles.icon}>{item.icon}</div>
            <div className={styles.description}>
              <div className={styles.name}>{item.name}</div>
              {/* <div className={styles.text}>{item.text}</div> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Benefits;
