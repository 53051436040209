import PrepareData from "helpers/PrepareData";

const objectsApi = async (country) => {
  const fetchObjects = await fetch(
    `https://dom-strapi.ipoteka.center/api/houses?populate=*&pagination[pageSize]=200`
  );

  const data = await fetchObjects.json();
  const preparedObjects = PrepareData(data);

  return preparedObjects;
};

export default objectsApi;
