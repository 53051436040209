import React from "react";
import styles from "./estateItems.module.scss";
import { ReactComponent as FixIcon } from "./images/icon_fix.svg";
import { ReactComponent as DownloadIcon } from "./images/icon_download.svg";
import { useDispatch } from "react-redux";
import { showPopup } from "store/objectSlice";
import WordDeclination from "helpers/WordDeclination";
import FormatNumber from "helpers/FormatNumber";

function EstateItem({ item, handler }) {
  const dispatch = useDispatch();

  const bgStyle = {
    background: `url(https://dom-strapi.ipoteka.center${item.photo}) left top / cover no-repeat`,
    // backgroundSize: "cover"
  };

  const handleFixClick = () => {
    handler(item);
  };

  const showObjectPopup = (event) => {
    let target = event.target;
    console.log('target', target);
    if(target.getAttribute('data-type') != 'presentation') {
      dispatch(showPopup({ item }));
    }
  };

  function getPresentationLink(presentation) {
    if (presentation != false) {
      return (
        <div className={styles.object__link}>
          <DownloadIcon />
          <a data-type='presentation' href={item.presentation} target="_blank" className={styles.object__linkText}>
            Презентация
          </a>
        </div>
      );
    } else return null;
  }

  function getTypesBadges(types) {
    let badges;
    if (types != false) {
      badges = types.map((item, index) => {
        return (
          <div className={styles.object__type} key={index}>
            {item.attributes.type}
          </div>
        );
      });
    }
    return badges;
  }

  function getPrice(itemPrice) {
    if(itemPrice!=false) {
      return 'от ' + FormatNumber(itemPrice) + ' руб.';
    }
    return null;
  }

  return (
    <div className={styles.object} onClick={showObjectPopup}>
      <div style={bgStyle} className={styles.object__img}>
        
      </div>
      <div className={styles.object__content}>
        <div className={styles.object__topContent}>
          <div className={styles.object__company}>{item.company}</div>
          <div className={styles.object__name}>{item.name}</div>
          <div className={styles.object__price}>
            {item.price != null ? getPrice(item.price) : ''}
            </div>
            <div className={styles.object__description}>
              <div className={styles.object__description_line}>
                <div className={styles.object__area}>
                  {item.area ? item.area + " м²" : ""}
                </div>
                <div className={styles.object__description_item}>
                  •
                </div>
                <div className={styles.object__floors}>
                  {item.floors} {WordDeclination(item.floors, 'этаж', 'этажа','этажей')}
                </div>
              </div>
              <div className={styles.object__description_line}>
                <div className={styles.object__description_item}>
                  {item.bedroom_number ? item.bedroom_number + " " + WordDeclination(item.bedroom_number, 'спальня', 'спальни','спален') : ""}
                </div>
                <div className={styles.object__description_item}>
                  •
                </div>
                <div className={styles.object__description_item}>
                  {item.bathroom_number ? item.bathroom_number + " " + WordDeclination(item.bathroom_number, 'ванная', 'ванные','ванных') : ""}
                </div>
              </div>
              <div className={styles.object__description_line}>
                <div className={styles.object__description_item}>
                  {item.foundation ? item.foundation + " фундамент" : ""}
                </div>
                <div className={styles.object__description_item}>
                  •
                </div>
                <div className={styles.object__description_item}>
                  {item.wall_material ? item.wall_material  : ""}
                </div>
              </div>
            </div>
        </div>
        <div className={styles.object__buttons}>
          <div className={styles.object__link} onClick={showObjectPopup}>
            <FixIcon />
            <span className={styles.object__linkText}>Подробнее</span>
          </div>
          {getPresentationLink(item.presentation)}
        </div>
      </div>
    </div>
  );
}

export default EstateItem;
