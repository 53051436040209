function PrepareWalls (data) {
    
    let result = data.data.map(item => {
        return {
            value: item.id,
            label: item.attributes.Type,
        }
    });
    

    
    return result;

}

export default PrepareWalls;
