import React from 'react';
import styles from './faq.module.scss';
import FaqItem from './FaqItem';

function Faq() {
        
    const content = [
        {
            q: "Что такое ИЖС?",
            a: "ИЖС – это индивидуальное жилищное строительство. На сегодняшний день эта форма собственности пользуется большим спросом, поскольку собственник, купив земельный участок, имеет право возвести на его территории дом и получить в нём прописку.",
        },
        {
            q: "Почему ИЖС?",
            a: <ul>
                    <li>
                        Можно законно построить собственный дом и прописаться в нем (независимо от того жить в нем постоянно или сезонно). После окончания строительства дома ему будет присвоен почтовый адрес.                    
                    </li>
                    <li>
                        Житель (владелец) получает все те же государственные услуги, которыми пользуются люди, прописанные в обычной городской квартире.                    
                    </li>
                    <li>
                        Имея прописку за чертой города, вы имеете льготы при оформлении страховки ОСАГО и налоговых вычетов.                    
                    </li>
                    <li>
                        Для земель с категорией ИЖС существует генеральный план застройки поселения, а это значит, что при соблюдении процедуры оформления ваш построенный дом не снесут как незаконную постройку.                    
                    </li>
                    <li>
                        Для покупки земли, имеющей категорию ИЖС, и строительства дома на ней можно получить ипотеку в банке.
                    </li>
                    <li>
                        Вы сможете оформить налоговый  вычет, так как по закону земли категории ИЖС и постройки на них предназначаются для постоянного проживания. Оформить вычет можно как за сам участок, так и за построенный дом.
                    </li>
                    <li>
                        Землевладения со статусом ИЖС находятся на государственном ведомстве – это благоустройство и уборка дорог, ремонт сетей коммуникаций, развитием социально-бытовой инфраструктуры.
                    </li>

                </ul>,
        },
        {
            q: "Как происходит сделка?",
            a: "Процесс покупки состоит из стандартных шагов, которые сопровождают большинство сделок с недвижимостью: найти подходящий объект ― подписать договор ― оплатить покупку.",
        },
        {
            q: "Можно ли купить дом в ипотеку?",
            a: "Конечно! Оставьте заявку на интересующий Вас объект. Наши менеджеры помогут оформить ипотеку с наиболее выгодными условиями.",
        },
        {
            q: "Сколько времени занимает проведение сделки?",
            a: "Сроки проведения зависят от схемы покупки дома. Если это ипотека - средний срок закрытия сделки 1,5 месяца. Если наличные - 20 календарных дней.",
        },
        {
            q: "Какие партнеры представлены на платформе?",
            a: "На платформе представлены только аккредитованные подрядные организации в Росбанк Дом.",
        }
    ];

  return (
    <div className={styles.faq}> 
      <div className={styles.heading}>
        Частые вопросы
      </div>
      {content.map((item, index) => <FaqItem item={item} key={index}/> )}
    </div>
  );
}

export default Faq;
