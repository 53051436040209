import React from "react";
import EstateItems from "components/estate/EstateItems";
import Faq from "components/FAQ/Faq";
import styles from "./content.module.scss";
import Button from "../../library/UI/Button";
import Benefits from "components/benefits/Benefits";
import { CitySelect } from "components/city-select/citySelect";
import { ReactComponent as RBlogo } from "../../library/icons/rb-logo.svg";
import { ReactComponent as IClogo } from "../../library/icons/ic-logo.svg";
import { ReactComponent as Logodivider } from "../../library/icons/divider-logo.svg";


function Content({ items, handler, pageName, status }) {

  return (
    <div className={styles.contentContainer}>
      <div className={styles.mainContainer}>
        <div className={styles.logo}>
          <a target='_blank' href='https://rosbank-dom.ru/mortgage-credit/stroyascheesya-zhile/individualnoe-zhilischnoe-stroitelstvo/'><RBlogo class={styles.companyLogo}/></a><Logodivider /><a target='_blank' href='https://partner.ipoteka.center/'><IClogo class={styles.companyLogo}/></a>
        </div>
       
        <div className={styles.pageHeader}>
          <h1 className="heading heading_M">
            Каталог проектов ИЖС
          </h1>
        </div>
        
        <a name="objects"></a>
        <EstateItems items={items} handler={handler} country={pageName} status={status} />
        {pageName === "Turkey" ? <CitySelect /> : ""}
        <Benefits page={pageName} />
        <Faq />
        <div className={styles.footer}>
          <a className={styles.footerLink} target="_blank" href="/policy.pdf">Политика конфиденциальности</a>
          <a className={styles.footerLink} target="_blank" href="/agreement.pdf">Пользовательское соглашение</a>
        </div>
      </div>
    </div>
  );
}

export default Content;
