import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import objectsApi from "services/objectsApi";
import filtersApi from "services/filtersApi";

export const fetchObjects = createAsyncThunk(
  "todos/fetchObjects",
  async function (country, { rejectWithValue }) {
    try {
      const objects = await objectsApi(country);
      return objects;
    } catch (error) {}
  }
);

export const fetchFilters = createAsyncThunk(
  "todos/fetchFilters",
  async function (country, { rejectWithValue }) {
    try {
      const objects = await filtersApi();
      return objects;
    } catch (error) {}
  }
);

const objectSlice = createSlice({
  name: "objects",
  initialState: {
    items: [],
    status: null,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchObjects.pending]: (state, action) => {
      state.status = "loading";
      state.error = null;
    },
    [fetchObjects.fulfilled]: (state, action) => {
      state.status = "resolved";
      state.items = action.payload;
      state.error = null;
    },
    [fetchObjects.rejected]: (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});

const filterSlice = createSlice({
  name: "filters",
  initialState: {
    items: [],
    status: null,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchFilters.pending]: (state, action) => {
      state.status = "loading";
      state.error = null;
    },
    [fetchFilters.fulfilled]: (state, action) => {
      state.status = "resolved";
      state.items = action.payload;
      state.error = null;
    },
    [fetchFilters.rejected]: (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});

const popupSlice = createSlice({
  name: "popup",
  initialState: {
    status: false,
    item: null,
  },
  reducers: {
    showPopup(state, action) {
      state.status = true;
      state.item = action.payload.item;
    },
    hidePopup(state, action) {
      state.status = false;
    },
  },
});

export const { showPopup, hidePopup } = popupSlice.actions;

export const objectReducer = objectSlice.reducer;
export const filterReducer = filterSlice.reducer;

export const popupReducer = popupSlice.reducer;
