function PrepareData (data) {
    
    let result = data.data.map(item => {
        
        let regions = item.attributes.regions.data ? item.attributes.regions.data : null; 
        regions = regions.map(regionItem => {
            return regionItem.attributes.region_name;
        });
        return {
            id: item.id,
            name: item.attributes.object_name,
            area: item.attributes.area !== null ? item.attributes.area : null,
            price: item.attributes.price !== null ? item.attributes.price : false,
            floors: item.attributes.floors !== null ? item.attributes.floors : false,
            bonus: item.attributes.bonus !== null ? item.attributes.bonus : false,
            presentation: item.attributes.presentation !== null ? item.attributes.presentation : false,
            bonus_amount: item.attributes.bonus_amount !== null ? item.attributes.bonus_amount : false,
            bedroom_number: item.attributes.bedroom_number !== null ? item.attributes.bedroom_number : false,
            bathroom_number: item.attributes.bathroom_number !== null ? item.attributes.bathroom_number : false,
            company: item.attributes.house_developer.data ? item.attributes.house_developer.data.attributes.developer_name : false,
            photo: item.attributes.picture.data !== null ? item.attributes.picture.data.attributes.url : false,
            foundation: item.attributes.fondation.data !== null ? item.attributes.fondation.data.attributes.fondation_type : false,
            regions: regions,
            wall_material: item.attributes.wall_material.data !== null ? item.attributes.wall_material.data.attributes.Type : false,
        }
    });
    

    
    return result;

}

export default PrepareData;
