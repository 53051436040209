import React, { useEffect } from "react";
import Content from "../components/content/Content";
import { useSelector, useDispatch } from "react-redux";
import { fetchObjects, fetchFilters } from "store/objectSlice";

function Emirates() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchObjects("Emirates"));
  }, [dispatch]);

  const objects = useSelector((state) => state.objects.items);
  const status = useSelector((state) => state.objects.status);


  return (
    <div>
      <Content items={objects} status={status} pageName="Emirates" />
    </div>
  );
}
export default Emirates;
